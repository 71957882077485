import { useEffect, useState } from 'react';
import {Link} from 'react-router-dom';
import LogoTitle from '../../assets/images/logo-s.png'
import AnimatedLetters from '../AnimatedLetters';
import Logo from './Logo';
import Loader from 'react-loaders'
import './index.scss';



const Home = () =>{

    const[lettersClass,setLetterClass]=useState('text-animate')
    const nameArray= ['m','r','e',' ','O', 'n','u','r',]
    const jobArray= ['w','e','b',' ','d','e','v','e','l','o','p','e','r',]

    useEffect(() => {
        
        let timeoutId = setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 4000)
        
        return () => {
                    clearTimeout(timeoutId)
                }
    }, [])

        return(
            <>
            <div className='container home-page' >
                <div className='text-zone'>

                    <h1>
                    <span className={lettersClass}>M</span>
                    <span className={`${lettersClass} _12`}>e</span>
                    <span className={`${lettersClass} _13`}>r</span>
                    <span className={`${lettersClass} _14`}>h</span>
                    <span className={`${lettersClass} _15`}>a</span>
                    <span className={`${lettersClass} _16`}>b</span>
                    <span className={`${lettersClass} _17`}>a</span> 
                    <br/>
                    <span className={`${lettersClass} _18`}>B</span>
                    <span className={`${lettersClass} _19`}>e</span>
                    <span className={`${lettersClass} _20`}>n</span> 
                    <img src={LogoTitle} alt="developer"/>
                    <AnimatedLetters lettersClass={lettersClass} strArray={nameArray} idx={7}/>
                    
                    <br/> 
                    <AnimatedLetters lettersClass={lettersClass} strArray={jobArray} idx={15}/>
                     </h1>
                    <h2>fronted developer / student</h2>
                    <Link to="/contact" className='flat-button'>İletişim</Link>






                </div>



<Logo />

            </div>
            <Loader type="pacman"/>
            </>
// {/* <div className="container home-page">
//     <div className="text-zone">
//         <h1>Merhaba,<br /> Ben
//         <img src={LogoTitle} alt="developer"/>
//         mre Onur
//         <br />
//         Web Yazılımcısı
//         </h1>
//         <h2>
//             Fronted Developer / Stajyer
//         </h2>
//         <Link to="/contact" className="flat-button"></Link>

//     </div>

// </div> */}
)
}

export default Home