import { useEffect, useState } from 'react'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import { useRef } from 'react'  
import emailjs from '@emailjs/browser'
import './index.scss'


const Contact = () =>{
    const form = useRef()
    const[lettersClass,setLetterClass]=useState('text-animate')
    useEffect(() => {
        
        let timeoutId = setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 4000)
        
        return () => {
                    clearTimeout(timeoutId)
                }
    }, [])
    
    const sendEmail = (e) => {
        e.preventDefault()
    
        emailjs
          .sendForm('gmail', 'template_YeJhZkgb', form.current, 'WceUkusMMGMrfuLVjky61')
          .then(
            () => {
              alert('Message successfully sent!')
              window.location.reload(false)
            },
            () => {
              alert('Failed to send the message, please try again')
            }
          )
      }
    
return(
    <>
    <div className='container contact-page'>
        <div className='text-zone'>
            <h1>
                <AnimatedLetters lettersClass={lettersClass} strArray={['M','a','i','l',]} idx={15}/>
            </h1>
            <p>Benimle iletişime geçmek için isterseniz menü barındaki iletişim adreslerini seçebilirsiniz.<br/>Veyahut aşağıdaki mail atabilirsiniz. </p>
            <div className="contact-form">
            <form ref={form} onSubmit={sendEmail}>
              <ul>
                <li className="half">
                  <input placeholder="Isminizi girin" type="text" name="name" required />
                </li>
                <li className="half">
                  <input
                    placeholder="Email"
                    type="email"
                    name="email"
                    required
                  />
                </li>
                <li>
                  <input
                    placeholder="Subject"
                    type="text"
                    name="subject"
                    required
                  />
                </li>
                <li>
                  <textarea
                    placeholder="Mesaj yazin"
                    name="message"
                    required
                  ></textarea>
                </li>
                <li>
                  <input type="submit" className="flat-button" value="GÖNDER" />
                </li>
              </ul>
            </form>
          </div>
        </div>
       
        <iframe width="520" height="397" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=397&amp;hl=en&amp;q=Gemlik%20Bursa+(Adres)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
    </div>
    
    <Loader type="pacman"/>
    </>
)

}


export default Contact