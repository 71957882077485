import './index.scss'
import LogoS from '../../assets/images/logo-s.png'
import { useState } from 'react'
import LogoSubtitle from '../../assets/images/logo_sub.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faLinkedin,
    faGithub,
    faYoutube,
    faInstagram,
  } from '@fortawesome/free-brands-svg-icons'
  import {
    faHome,
    faUser,
    faEnvelope,
    faBars,
    faClose,
    faGamepad,
  } from '@fortawesome/free-solid-svg-icons'
import {Link, NavLink} from 'react-router-dom'


const Sidebar = () => {
    const [showNav, setShowNav] = useState(false);
    return (
        <div className="nav-bar">
          <Link 
            className="logo"
            to="/"
            onClick={() => setShowNav(false)}>
            <img src={LogoS} alt="Logo" />
            <img className="sub-logo" src={LogoSubtitle} alt="Emre Onur" />
          </Link>
          <nav className={showNav ? 'mobile-show' : ''}>
            <NavLink 
              exact="true"
              activeclassname="active"
              to="/"
              onClick={() => setShowNav(false)}>
              <FontAwesomeIcon icon={faHome} color="#4d4d4e" />
            </NavLink>
            <NavLink 
              activeclassname="active"
              className="about-link"
              to="/about"
              onClick={() => setShowNav(false)}>
              <FontAwesomeIcon icon={faUser} color="#4d4d4e" />
            </NavLink>
            <NavLink
              activeclassname="active"
              className="contact-link"
              to="/contact"
              onClick={() => setShowNav(false)}
            >
              {/* <FontAwesomeIcon icon={faGamepad} color="#4d4d4e" />
            </NavLink>
            <NavLink
              activeclassname="active"
              className="contact-link"
              to="/contact"
              onClick={() => setShowNav(false)}
            > */}
              <FontAwesomeIcon icon={faEnvelope} color="#4d4d4e" />
            </NavLink>
            <FontAwesomeIcon 
              onClick={() => setShowNav(false)}
              icon={faClose}
              color="#ffd700"
              size="3x"
              className='close-icon' />
          </nav>
          <ul>
            <li>
              <a
                href="https://tr.linkedin.com/in/emre-onur-kalafat-8b3b5b241?trk=public_profile_samename-profile"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  icon={faLinkedin}
                  color="#4d4d4e"
                  className="anchor-icon"
                />
              </a>
            </li>
            <li>
              <a
                href="https://github.com/wachetr"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  icon={faGithub}
                  color="#4d4d4e"
                  className="anchor-icon"
                />
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UCQ1OwlISn08VTn2wBGx4fxA"
                rel="noreferrer"
                target="_blank"
              >
                <FontAwesomeIcon
                  icon={faYoutube}
                  color="#4d4d4e"
                  className="anchor-icon"
                />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/emreonur195/" rel="noreferrer" target="_blank">
                <FontAwesomeIcon
                  icon={faInstagram}
                  color="#4d4d4e"
                  className="anchor-icon"
                />
              </a>
            </li>
          </ul>
          <FontAwesomeIcon 
              onClick={() => setShowNav(true)}
              icon={faBars}
              color="#ffd700"
              size="3x"
              className='hamburger-icon' />
        </div>
      )
}

export default Sidebar