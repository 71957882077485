import './index.scss'
import ben from '../../../assets/images/asd.png'


const Logo =() =>{
    return(
        <div className="logo-container">
            <img className="solid-logo" src={ben} />

        </div>
    )
}

export default Logo