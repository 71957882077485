import AnimatedLetters from "../AnimatedLetters"
import { useEffect, useState } from 'react';
import Loader from 'react-loaders'
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faAngular, faCss3, faGitAlt, faHtml5, faJsSquare, faReact} from '@fortawesome/free-brands-svg-icons'
import './index.scss'

const About=() =>{
    const[lettersClass,setLetterClass]=useState('text-animate')
    useEffect(() => {
        
        let timeoutId = setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 4000)
        
        return () => {
                    clearTimeout(timeoutId)
                }
    }, [])
    
return(
 <>
    <div className="container about-page">

        <div className="text-zone">

            <h1>
                <AnimatedLetters lettersClass={lettersClass} strArray={['H','A','K','K','I','M','D','A']} idx={15}/>
            </h1>
                <p>Merhaba ben Emre Onur Kalafat, 21 yaşında Gemlik MYO 2.Sınıf Bilgisayar Programcılığını okuyorum.</p>
                <p>Şuan F1 Yazılımda staj yapmaktayım. Kripto yazılımlar üzerine eğitim almaktayım. Şuan üzerinde çalışmakta olduğum bir JS oyun projesi bulunmaktadır. Bildiğim diller ve hakim olduğum alanlar dönen küpün içerisinde bulunmaktadır.</p>
                <p>İlerideki misyonum kendimi dijital tasarım ve oyun programlamada geliştirmek. C# ve Unity hakimiyetimi artırmaya çalışmaktayım.</p>
        </div>
<div className="stage-cube-cont">
<div className="cubespinner">
    <div className="face1">
        <FontAwesomeIcon icon={faAngular} color="#DD0031"/>
    </div>
    <div className="face2">
        <FontAwesomeIcon icon={faHtml5} color="#F06529"/>
    </div>
    <div className="face3">
        <FontAwesomeIcon icon={faCss3} color="#28A4D9"/>
    </div>
    <div className="face4">
        <FontAwesomeIcon icon={faReact} color="#5FD"/>
    </div>
    <div className="face5">
        <FontAwesomeIcon icon={faJsSquare} color="#EFD81D"/>
    </div>
    <div className="face6">
        <FontAwesomeIcon icon={faGitAlt} color="#EC4D28"/>
    </div>
</div>

</div>

    </div>
    <Loader type="pacman"/>
    </>
)

}


export default About